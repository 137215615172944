<template>
  <div class="feed-notification">
    <div class="notification-author">
      <img src="@/assets/spartan_logo.svg" alt="logo" />
      <div class="author-info">
        <div class="author-name">
          {{ notificationData.author.title }}
        </div>
        <div class="author-subline">
          {{ notificationData.category }}
        </div>
      </div>
    </div>
    <div class="notification-content">
      <div class="notification-title">
        {{ notificationData.header }}
      </div>
      <div class="notification-buttons">
        <mem-button
          @click="openLink(notificationData.directions_button.url)"
          :btnType="'tertiary-dark'"
          class="button-directions"
          >{{ notificationData.directions_button.label }}</mem-button
        >
        <mem-button
          @click="openLink(notificationData.tickets_button.url)"
          :btnType="'secondary-dark'"
          class="button-tickets"
          >{{ notificationData.tickets_button.label }}</mem-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    testLink: "https://account-staging.spartan.com/events/7553",
    testLink2: "https://maps.google.com/?q=test%2C+test%2C+test%2C+test%2C+123",
  }),
  props: {
    notificationData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  methods: {
    openLink(link) {
      if (!link) return;

      const accountHosts = [
        "account.spartan.com",
        "account-staging.spartan.com",
      ];
      let linkObj = new URL(link);

      if (accountHosts.includes(linkObj.host))
        return this.$router.push({ path: linkObj.pathname });

      let windowReference = window.open();
      windowReference.location = link;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.feed-notification {
  min-height: 196px;
  background-color: #901729;
  padding: 40px 50px 20px;
  margin-bottom: 4px;
  @media screen and (max-width: $mobile) {
    min-height: 182px;
    padding: 20px 16px 20px;
  }
  .notification-author {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    @media screen and (max-width: $mobile) {
      margin-bottom: 34px;
    }
    img {
      height: 40px;
      width: 40px;
      margin-right: 16px;
      @media screen and (max-width: $mobile) {
        height: 34px;
        width: 34px;
        margin-right: 12px;
      }
    }
    .author-info {
      .author-name {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 6px;
        @media screen and (max-width: $mobile) {
          margin-bottom: 4px;
        }
      }
      .author-subline {
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        letter-spacing: 0.005em;
      }
    }
  }
  .notification-content {
    max-width: 460px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr max-content;
    @media screen and (max-width: $mobile) {
      grid-template-columns: 1fr;
    }
    .notification-title {
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
      @media screen and (max-width: $mobile) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }
    .notification-buttons {
      @media screen and (max-width: $mobile) {
        display: flex;
        justify-content: flex-end;
      }
      .button-directions,
      .button-tickets {
        height: 34px;
        padding: 0 16px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;

        margin-left: 9px;
      }
    }
  }
}
</style>